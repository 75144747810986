import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import Button from "../ui/Button";
import Typography from "../ui/Typography.js";

const api = "https://api-prod.cardiovelo.pl/api/v1/contact";

const Form = () => {
  const { handleSubmit, register, reset } = useForm();
  const [responseContent, setResponseContent] = useState();

  const onSubmit = (form) => {
    if (!form) return;

    axios
      .post(api, form)
      .then(() => {
        setResponseContent("Wiadomość została wysłana, dziękujemy!");

        reset({
          fullName: undefined,
          userEmail: undefined,
          title: undefined,
          content: undefined,
        });
      })
      .catch(() =>
        setResponseContent(
          "Wysyłanie nie powiodło się, prosimy o kontakt mailowy."
        )
      );
  };

  return (
    <form
      className="form col-5 col-md-12 col-sm-12"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Typography customClass="form-heading mb-30 ml-10">
        Skontaktuj się z nami przez formularz:
      </Typography>
      <div className="form-row__personal-details">
        <div className="form-row col-6 col-md-12 col-sm-12">
          <label htmlFor="fullName">Imię i nazwisko</label>
          <input
            {...register("fullName")}
            type="text"
            id="fullName"
            placeholder="Wpisz imię i nazwisko"
            required
          />
        </div>
        <div className="form-row col-6 col-md-12 col-sm-12">
          <label htmlFor="userEmail">Email</label>
          <input
            {...register("userEmail")}
            type="email"
            id="userEmail"
            placeholder="Wpisz adres e-mail"
            required
          />
        </div>
      </div>
      <div className="form-row col-md-12 col-sm-12">
        <label htmlFor="title">Temat</label>
        <input
          {...register("title")}
          type="text"
          id="title"
          placeholder="Wpisz temat wiadomości"
          required
        />
      </div>
      <div className="form-row col-md-12 col-sm-12">
        <label htmlFor="content">Treść</label>
        <textarea
          {...register("content")}
          name="content"
          id="content"
          placeholder="Wpisz treść wiadomości"
          required
        />
      </div>
      <div className="form-row mt-30">
        <Button>
          <Typography>Wyślij wiadomość</Typography>
        </Button>
      </div>
      <div className="form-row mt-30 form__status-info">
        {responseContent ? (
          <Typography customClass="status__content">
            {responseContent}
          </Typography>
        ) : null}
      </div>
    </form>
  );
};

export default Form;
