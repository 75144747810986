import React, { useState } from "react";
import Hamburger from "../assets/icons/hamburger.svg";
import ExitButton from "../assets/icons/exit-button.svg";

const Menu = ({ navigationItems }) => {
  const [navigationStatus, setNavigationActive] = useState(false);

  const handleClick = (href) => {
    setNavigationActive(false);
    document.getElementById(href).scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="navigation--mobile col-12">
      <button
        className="navigation__button"
        onClick={() => setNavigationActive(!navigationStatus)}
      >
        {navigationStatus ? (
          <img src={ExitButton} alt="X" />
        ) : (
          <img src={Hamburger} alt="Menu" />
        )}
      </button>
      <nav
        className={`${
          navigationStatus
            ? "navigation__list--active"
            : "navigation__list--inactive"
        }`}
      >
        {navigationItems.map((item) => (
          <button
            key={item.href}
            className="navigation__item"
            onClick={() => handleClick(item.href)}
          >
            {item.label}
          </button>
        ))}
      </nav>
    </div>
  );
};

export default Menu;
