import React from "react";
import Button from "../ui/Button";
import Title from "../ui/Title";
import Typography from "../ui/Typography";
import SubImage from "../assets/backgrounds/cyclist.png";
import DownloadBoth from "../assets/icons/download-both.svg";

const Start = () => (
  <section className="start">
    <div className="start__background-image--main">
      <div className="start__welcome-inscription col-4 col-lg-6 col-md-8 col-sm-8">
        <Title>Cardiovelo</Title>
        <Typography>
          Masz problemy sercowe, ale chcesz wrócić do aktywności fizycznej? A
          może chcesz schudnąć? Cardiovelo to aplikacja do sprawnego zarządzania
          treningami rowerowymi, która pomoże Ci osiągnąć zamierzone cele wraz z
          specjalistami!
        </Typography>
        <Button
          customClass="mt-30 col-8 col-md-12 col-sm-10"
          onClick={() =>
            document
              .getElementById("pobierz")
              .scrollIntoView({ behavior: "smooth" })
          }
        >
          <Typography>Pobierz aplikację</Typography>
          <img src={DownloadBoth} alt="Pobierz aplikację" />
        </Button>
      </div>
    </div>
    <img
      className="start__background-image--cyclist"
      src={SubImage}
      alt="Zdjęcie rowerzysty"
    />
  </section>
);

export default Start;
