import React from "react";
import Typography from "../ui/Typography";
import FooterLogos from "../assets/icons/footer-logos.svg";

const Footer = () => (
  <footer className="footer">
    <div className="row">
      <div className="col-6 col-md-12 col-sm-12">
        <Typography>
          &copy; 2021 All copyrights reserved by Cardiovelo
        </Typography>
      </div>
      <div className="footer__images col-6 col-md-12 col-sm-12">
        <img src={FooterLogos} alt="Logo Funduszu Europejskiego, UE i RP" />
      </div>
    </div>
  </footer>
);

export default Footer;
