import React from "react";
import Circle from "../ui/Circle";
import Subtitle from "../ui/Subtitle";
import Typography from "../ui/Typography";
import Training from "../assets/icons/training.svg";
import BikeRental from "../assets/icons/bike-rental.svg";
import People from "../assets/icons/people.svg";

const Application = () => (
  <section className="application" id="aplikacja">
    <div className="row justify-content-center">
      <div className="application__description col-3 col-lg-4 col-md-10 col-sm-10">
        <Circle customClass="rental" src={BikeRental} alt="Wypożyczalnie" />
        <Subtitle customClass="col-7 col-sm-10">
          SZEROKI DOSTĘP DO WYPOŻYCZALNI
        </Subtitle>
        <Typography customClass="col-6">
          Nie masz roweru? W aplikacji znajdziesz duży wybór rowerów
          gdziekolwiek jesteś na liście wypożyczalni w wielu miastach
        </Typography>
      </div>
      <div className="application__description col-3 col-lg-4 col-md-10 col-sm-10">
        <Circle customClass="people" src={People} alt="Specjaliści" />
        <Subtitle customClass="col-7 col-sm-10">
          OSIĄGAJ ZAMIERZONE CELE POD OPIEKĄ SPECJALISTÓW
        </Subtitle>
        <Typography customClass="col-6">
          Twój specjalista w łatwy sposób ustala i modyfikuje trening pod
          założone cele
        </Typography>
      </div>
      <div className="application__description col-3 col-lg-4 col-md-10 col-sm-10">
        <Circle customClass="training" src={Training} alt="Trening" />
        <Subtitle customClass="col-7 col-sm-10">
          TRENING DOPASOWANY DO TWOICH POTRZEB
        </Subtitle>
        <Typography customClass="col-6">
          Skutecznie założone cele treningowe zgodne z Twoimi potrzebami i
          motywacjami
        </Typography>
      </div>
    </div>
  </section>
);

export default Application;
