import React from "react";
import { Helmet } from "react-helmet";
import "../styles/main.scss";
import Header from "../componenets/Header";
import Start from "../sections/Start";
import Application from "../sections/Application";
import Customers from "../sections/Customers";
import Functions from "../sections/Functions";
import Download from "../sections/Download";
import Contact from "../sections/Contact";
import View from "../sections/View";
import Footer from "../sections/Footer";

const App = () => {
  return (
    <div className="app">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cardiovelo</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Helmet>
      <Header />
      <main>
        <Start />
        <Application />
        <Functions />
        <Customers />
        <View />
        <Download />
        <Contact />
      </main>
      <Footer />
    </div>
  );
};

export default App;
