import React, { useState } from "react";
import { Slider as Carousel } from "infinite-react-carousel";
import View_zero from "../assets/pictures/widok_0.png";
import View_one from "../assets/pictures/widok_1.png";
import View_two from "../assets/pictures/widok_2.png";
import View_three from "../assets/pictures/widok_3.png";
import View_four from "../assets/pictures/widok_4.png";
import View_five from "../assets/pictures/widok_5.png";
import View_six from "../assets/pictures/widok_6.png";
import View_seven from "../assets/pictures/widok_7.png";
import ChevronLeft from "../assets/icons/chevron-left.png";
import ChevronRight from "../assets/icons/chevron-right.png";

const Slider = () => {
  const [itemsToShow, setItemsToShow] = useState(5);

  const onWidthChange = (windowWidth) => {
    if (windowWidth < 575) {
      return setItemsToShow(1);
    }

    if (windowWidth < 1366) {
      return setItemsToShow(3);
    }

    if (windowWidth > 1366) {
      return setItemsToShow(5);
    }
  };

  const slides = [
    {
      id: 0,
      src: View_six,
    },
    {
      id: 1,
      src: View_seven,
    },
    {
      id: 2,
      src: View_zero,
    },
    {
      id: 3,
      src: View_one,
    },
    {
      id: 4,
      src: View_two,
    },
    {
      id: 5,
      src: View_three,
    },
    {
      id: 6,
      src: View_four,
    },
    {
      id: 7,
      src: View_five,
    },
  ];

  const settings = {
    centerMode: true,
    slidesToShow: itemsToShow,
    onResize: (e) => onWidthChange(e.currentTarget.innerWidth),
    dots: true,
    arrows: itemsToShow === 1 ? false : true,
    prevArrow: (
      <button className="slider__arrow--left">
        <img src={ChevronLeft} alt="Lewo" />
      </button>
    ),
    nextArrow: (
      <button className="slider__arrow--right">
        <img src={ChevronRight} alt="Prawo" />
      </button>
    ),
  };

  return (
    <div className="slider">
      <Carousel {...settings}>
        {slides.map((slide) => (
          <img src={slide.src} alt="Slide" id={slide.id} />
        ))}
      </Carousel>
    </div>
  );
};

export default Slider;
