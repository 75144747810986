import React from "react";
import Title from "../ui/Title";
import Circle from "../ui/Circle";
import Subtitle from "../ui/Subtitle";
import Heading from "../componenets/Heading";
import Typography from "../ui/Typography";
import AppView from "../assets/pictures/widok_0.png";
import Tracking from "../assets/icons/map.svg";
import Medic from "../assets/icons/medic.svg";
import Kilograms from "../assets/icons/kilograms.svg";
import Care from "../assets/icons/care.svg";
import Rentals from "../assets/icons/rentals.svg";
import Statistic from "../assets/icons/statistic.svg";

const Functions = () => {
  const largeDevicesContent = (
    <div className="content__large-devices">
      <div className="d-flex mt-20">
        <Circle customClass="rental" src={Rentals} alt="Wypożyczalnie" />
        <div className="content__info-col">
          <Subtitle>PODGLĄD WYPOŻYCZALNI I DOSTĘPNEGO SPRZĘTU</Subtitle>
          <Typography>
            podgląd na dostępne wypożyczalnie z danymi kontaktowymi i dostępnymi
            rowerami
          </Typography>
        </div>
      </div>
      <div className="d-flex">
        <Circle src={Medic} alt="Mierzenie funkcji życiowych" />
        <div className="content__info-col">
          <Subtitle>MIERZENIE FUNKCJI ŻYCIOWYCH</Subtitle>
          <Typography>
            aplikacja zintegrowana z rowerem monitoruje Twój puls, kadencję, a
            także ciśnienie interwałowo
          </Typography>
        </div>
      </div>
      <div className="d-flex">
        <Circle
          customClass="history"
          src={Statistic}
          alt="Historia treningów"
        />
        <div className="content__info-col">
          <Subtitle>HISTORIA TRENINGÓW</Subtitle>
          <Typography>
            statystyki spalonych kalorii i przebytych kilometrów w skali
            tygodnia/miesiąca/roku, a także zrealizowane cykle treningowe
          </Typography>
        </div>
      </div>
    </div>
  );

  return (
    <section className="functions" id="funkcje">
      <Heading>
        <Title>Jak działa aplikacja Cardiovelo?</Title>
        <Typography customClass="col-8 col-md-12 col-sm-12">
          Cardiovelo działa tylko i wyłącznie w połączeniu z rowerem
          elektrycznym i czujnikiem tętna oraz tylko i wyłącznie po
          specjalistycznej konsultacji z własnym lekarzem, terapeutą czy
          dietetykiem
        </Typography>
      </Heading>
      <div className="content">
        <div className="col-4 content__side--left">
          <div className="d-flex justify-content-flex-end">
            <div className="content__info-col">
              <Subtitle>PODGLĄD WYPOŻYCZALNI I DOSTĘPNEGO SPRZĘTU</Subtitle>
              <Typography>
                podgląd na dostępne wypożyczalnie z danymi kontaktowymi i
                dostępnymi rowerami
              </Typography>
            </div>
            <Circle customClass="rental" src={Rentals} alt="Wypożyczalnie" />
          </div>
          <div className="d-flex justify-content-flex-end">
            <div className="content__info-col">
              <Subtitle>MIERZENIE FUNKCJI ŻYCIOWYCH</Subtitle>
              <Typography>
                za pomocą aplikacji możliwe jest monitorowanie Twojego pulsu
                serca
              </Typography>
            </div>
            <Circle src={Medic} alt="Mierzenie funkcji życiowych" />
          </div>
          <div className="d-flex justify-content-flex-end">
            <div className="content__info-col">
              <Subtitle>HISTORIA TRENINGÓW</Subtitle>
              <Typography>
                praktyczne statystyki postępów treningowych w skali tygodnia
                /miesiąca / roku, a także zrealizowane cykle treningowe
              </Typography>
            </div>
            <Circle
              customClass="history"
              src={Statistic}
              alt="Historia treningów"
            />
          </div>
        </div>
        <div className="functions__app-view col-4 col-lg-5 col-md-6 col-sm-12">
          <img src={AppView} alt="Ramka" />
        </div>
        <div className="col-4 col-lg-5 col-md-6 col-sm-10 content__side--right">
          {largeDevicesContent}
          <div className="d-flex">
            <Circle
              customClass="specialist"
              src={Care}
              alt="Opieka specjalisty"
            />
            <div className="content__info-col">
              <Subtitle>BIEŻĄCA OPIEKA SPECJALISTY</Subtitle>
              <Typography>
                to lekarz, terapeuta lub dietetyk wprowadza do waszego profilu
                parametry obciążeń odpowiednie dla Twojego zdrowia
              </Typography>
            </div>
          </div>
          <div className="d-flex">
            <Circle src={Tracking} alt="Mapa" />
            <div className="content__info-col">
              <Subtitle>PODGLĄD AKTUALNEJ TRASY</Subtitle>
              <Typography>
                dostępny w trakcie trwania treningu, wraz z bieżącymi
                informacjami na temat Twojego zdrowia, tempa, czasu trwania czy
                dystansu
              </Typography>
            </div>
          </div>
          <div className="d-flex">
            <Circle src={Kilograms} alt="Kontrola wspomagania roweru" />
            <div className="content__info-col">
              <Subtitle>KONTROLA WSPOMAGANIA ROWERU</Subtitle>
              <Typography>
                W przypadku przekroczenia zaordynowanego przez lekarza tętna,
                system zmodyfikuje poziom wspomagania roweru
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Functions;
