import React from "react";
import Title from "../ui/Title";
import Heading from "../componenets/Heading";
import Typography from "../ui/Typography";
import Button from "../ui/Button";
import iOS from "../assets/icons/app-store.svg";
import Android from "../assets/icons/google-play.svg";

const Download = () => (
  <section className="download" id="pobierz">
    <Heading>
      <Title>Pobierz aplikację Cardiovelo</Title>
      <Typography customClass="col-8 col-md-10 col-sm-10">
        Wszystkie funkcjonalności Cardiovelo możesz wypróbować już teraz! Nasza
        aplikacja jest dostępna zarówno na system Android jak i IOS, dzięki
        czemu nie spotkasz żadnych przeszkód komunikując się ze swoim
        specjalistą lub podopiecznymi.
      </Typography>
    </Heading>
    <Typography>Pobierz już teraz:</Typography>
    <div className="row justify-content-center mt-50">
      <Button customClass="download__button mr-10">
        <img src={iOS} alt="Pobierz na iOS" />
      </Button>
      <a
        href="https://play.google.com/store/apps/details?id=com.cardiovelo"
        target="_blank"
        rel="external"
      >
        <Button customClass="download__button">
          <img src={Android} alt="Pobierz na Androida" />
        </Button>
      </a>
    </div>
  </section>
);

export default Download;
