import React from "react";
import Logo from "../assets/icons/logo.svg";
import UE from "../assets/icons/ue-down.svg";
import Menu from "./Menu";

const Header = () => {
  const navigationItems = [
    { href: "aplikacja", label: "O aplikacji" },
    { href: "funkcje", label: "Funkcje" },
    { href: "widoki", label: "Widoki aplikacji" },
    { href: "kontakt", label: "Kontakt" },
  ];

  return (
    <header className="header">
      <div
        className="header__image-ue--left col-6"
        onClick={() =>
          document
            .getElementById("poster--small")
            .scrollIntoView({ behavior: "smooth" })
        }
      >
        <img src={UE} alt="Logo UE" />
      </div>
      <div className="header__image-logo col-4">
        <img src={Logo} alt="Cardiovelo logo" />
      </div>
      <Menu navigationItems={navigationItems} />
      <nav className="navigation col-4">
        {navigationItems.map((item) => (
          <button
            key={item.href}
            className="navigation__item"
            onClick={() =>
              document
                .getElementById(item.href)
                .scrollIntoView({ behavior: "smooth" })
            }
          >
            {item.label}
          </button>
        ))}
      </nav>
      <div
        className="header__image-ue--right col-4"
        onClick={() =>
          document
            .getElementById("poster")
            .scrollIntoView({ behavior: "smooth" })
        }
      >
        <img src={UE} alt="Logo UE" />
      </div>
    </header>
  );
};

export default Header;
