import React from "react";

const Button = ({ children, customClass, onClick }) => (
  <button
    className={`button ${customClass ? customClass : ""}`}
    onClick={onClick ? onClick : () => null}
  >
    {children}
  </button>
);

export default Button;
