import React from "react";
import Title from "../ui/Title";
import Heading from "../componenets/Heading";
import Typography from "../ui/Typography";
import Circle from "../ui/Circle";
import Email from "../assets/icons/email.svg";
import Phone from "../assets/icons/phone.svg";
import Form from "../componenets/Form";
import Poster from "../assets/pictures/ue-poster.png";
import PosterTablet from "../assets/pictures/ue-poster--tablet.png";

const Contact = () => (
  <section className="contact" id="kontakt">
    <Heading>
      <Title>Kontakt</Title>
      <Typography>
        Masz pytania? Z chęcią odpowiemy na twoje wątpliości!
      </Typography>
    </Heading>
    <div className="row justify-content-center">
      <div className="flex-col">
        <div className="flex-center mb-20">
          <Circle customClass="mr-20" src={Email} alt="Email"></Circle>
          <Typography>biuro@cardiovelo.pl</Typography>
        </div>
        <div className="d-flex align-items-center">
          <Circle customClass="mr-30" src={Phone} alt="Telefon"></Circle>
          <div className="flex-col">
            <Typography>+48 532 521 473</Typography>
          </div>
        </div>
      </div>
    </div>
    <div className="flex-center flex-col mt-50">
      <div className="contact-row align-items-flex-end col-12 col-md-10 col-sm-10">
        <Form />
        <div className="contact__poster col-lg-6 mb-30">
          <img
            id="poster"
            src={Poster}
            alt="Plakat UE"
            className="ue-poster col-md-6 col-sm-6"
          />
        </div>
        <img
          id="poster--small"
          src={PosterTablet}
          className="ue-poster--tablet col-6"
          alt="Plakat UE"
        />
      </div>
    </div>
  </section>
);

export default Contact;
