import React from "react";
import Slider from "../componenets/Slider";
import Heading from "../componenets/Heading";
import Typography from "../ui/Typography";
import Title from "../ui/Title";

const View = () => {
  return (
    <section className="view" id="widoki">
      <Heading>
        <Title>Widoki aplikacji</Title>
        <Typography customClass="col-8 col-md-10 col-sm-10">
          W Cardiovelo wszystko znajdziesz łatwo i szybko, wszystkie
          funkcjonalności są w zasięgu Twojej ręki. Masz dostęp do ustalonych
          celów treningowych oraz do specjalisty, który na bieżąco monitoruje
          Twoje postępy.
        </Typography>
      </Heading>
      <Slider />
    </section>
  );
};

export default View;
