import React from "react";
import Title from "../ui/Title";
import Heading from "../componenets/Heading";
import Typography from "../ui/Typography";
import IconDone from "../assets/icons/icon-done.png";

const Customers = () => {
  const contentLeft = [
    {
      content:
        "W szybki i precyzyjny sposób ustalane indywidualne cykle i jednostki treningowe dla Twojego pacjenta",
    },
    {
      content:
        "Monitorowanie postępów pacjenta za pomocą wygenerowanych z treningu wykresów i dopasowywanie obciążeń na ich podstawie",
    },
    {
      content:
        "Analiza danych podczas treningu w odniesieniu do ustalonych limitów górnych lub dolnych przekłada się na wzmocnienie lub osłabienie wspomagania podczas treningu",
    },
    {
      content:
        "Bezpieczeństwo pacjenta - aplikację można zintegrować z rowerem elektrycznym i czujnikiem tętna, monitorując przebieg rehabilitacji",
    },
  ];

  const contentRight = [
    {
      content:
        "Aplikacja pomoże schudnąć, poprawić wydolność serca, zredukować cholesterol, wzmocnić w sposób kontrolowany układ kostno-mięśniowy po przebytych kontuzjach i chorobach",
    },
    {
      content:
        "Analiza wykresu tętna (HR) w stosunku do czasu treningu (T) i jego dystansu, pozwoli lekarzowi dobierać optymalne dla Ciebie parametry",
    },
    {
      content:
        "To lekarz / terapeuta / dietetyk wprowadza do Waszego profilu parametry obciążeń odpowiednie dla celu, który powinniście uzyskać",
    },
    {
      content:
        "Po przebytym treningu możliwość podzielenia się mniejszym lub większym sukcesem na profilach społecznościowych",
    },
  ];

  return (
    <section className="customers">
      <Heading>
        <Title>Dla kogo jest Cardiovelo?</Title>
        <Typography customClass="col-8 col-md-10 col-sm-10">
          Cardiovelo przeznaczone jest zarówno dla użytkowników, którzy chcą
          poddać się zaplanowanej i poddanej kontroli specjalistów
          rehabilitacji, ale także dla trenerów, lekarzy czy dietetyków, którzy
          chcą na bieżąco kontrolować działania ich klientów i zlecać im kolejne
          plany treningowe.
        </Typography>
      </Heading>
      <div className="row justify-content-center">
        <div className="box mb-40">
          <div className="box__head">
            <Title>dla specjalisty</Title>
          </div>
          <div className="box__body">
            <ul className="box__list">
              {contentLeft.map((item, index) => (
                <li className="box__list-item" id={index}>
                  <img className="box__list-icon" src={IconDone} alt="" />
                  <Typography>{item.content}</Typography>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="box">
          <div className="box__head">
            <Title>dla osoby trenującej</Title>
          </div>
          <div className="box__body">
            <ul className="box__list">
              {contentRight.map((item, index) => (
                <li className="box__list-item" id={index}>
                  <img className="box__list-icon" src={IconDone} alt="" />
                  <Typography>{item.content}</Typography>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Customers;
